<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card title="Invoice Customer">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="thead">
            <vs-th sort-key="id">Invoice No.</vs-th>
            <vs-th sort-key="bill_name">Billed To</vs-th>
            <vs-th sort-key="date_transaction">Date</vs-th>
            <vs-th sort-key="grand_total">Grand Total</vs-th>
            <vs-th sort-key="grand_total">Leftover</vs-th>
            <vs-th sort-key="created_at">Status</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.code">
                <router-link :to="{name:`invoices-show`,params:{id: tr.invoice_id}}">{{tr.code}}</router-link>
              </vs-td>
              <vs-td label="Billed To" :data="tr.bill_name">{{tr.bill_name}}</vs-td>
              <vs-td label="Date" :data="tr.date_transaction">{{tr.date_transaction|moment('Do MMM YYYY')}}</vs-td>
              <vs-td label="Grand Total" class="text-right" :data="tr.grand_total">{{tr.grand_total|numFormat}}</vs-td>
              <vs-td label="Leftover" class="text-right" :data="tr.leftover">{{tr.leftover|numFormat}}</vs-td>
              <vs-td>
                <vs-chip v-if="tr.leftover<=0" color="success">Paid Off</vs-chip>
                <vs-chip v-if="tr.leftover>0" color="danger">Unpaid</vs-chip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  data(){
    return {
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.receivables.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'receivables/index',
      dispatchDestroy: 'receivables/destroy'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      await this.dispatchIndex(payload)
    },
    async confirmDelete(){
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>